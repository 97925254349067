/* eslint-disable react/prefer-stateless-function */
import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { truncate } from 'lodash'
import { FaCalendarAlt, FaPhone } from 'react-icons/fa'
import SEO from '../components/seo'
import Layout from '../components/Layout'
import NewsSnippet from '../components/NewsSnippet'
import MarkdownContent from '../components/MarkdownContent'
import getSuffix from '../utils/getSuffix'

import '../components/search.css'

export default ({ location }) => {
  if (location.state) {
    const numOfResults = location.state.results.length
    const { results } = location.state
    const snippetCutoff = 200 // cutoff after max. chars and append '...'
    return (
      <div>
        <SEO title="Wyniki wyszukiwania" />
        <Layout>
          <div className="search">
            <h1>
              {`Znaleziono ${numOfResults} wynik${getSuffix(numOfResults)}`}
            </h1>
            {results.map(page => (
              <ul>
                {page.templateKey === 'news-post' && (
                  <NewsSnippet
                    key={page.id}
                    linkDestination={page.link}
                    image={page.thumbnail}
                    imgAlt={page.id}
                    title={page.label}
                    shortDescription={page.description}
                  />
                )}
                {page.templateKey === 'schedule-page' && (
                  <li className="search__result" key={page.id}>
                    <Link className="search__result-materials-link" to={page.link}>
                      <FaCalendarAlt />
                    </Link>
                    <div className="search__result--right">
                      <Link to={page.link}>
                        <h3>{page.label}</h3>
                      </Link>
                    </div>
                  </li>
                )}
                {/^(?!.*(news-post|schedule-page)).*$/.test(page.templateKey) && (
                  <li className="search__result" key={page.id}>
                    <a href={page.link} className="search__result-materials-link">
                      {page.thumbnail ? (
                        <Img className="search__result--thumbnail" fluid={page.thumbnail.childImageSharp.fluid} alt={page.id} />
                      ) : (
                        <FaPhone />
                      )}
                    </a>
                    <div className="search__result--right">
                      <h3>
                        <a href={page.link}>{page.label}</a>
                      </h3>
                      <p>
                        <MarkdownContent content={truncate(page.description,
                          {
                            length: snippetCutoff,
                            separator: '/,? +/',
                          })}
                        />
                      </p>
                      {page.thumbnail && (
                        <Link to={page.link}>
                        Czytaj dalej →
                        </Link>
                      )}
                    </div>
                  </li>
                )}
              </ul>
            ))}
          </div>
        </Layout>
      </div>
    )
  }
  if (location.search !== '') {
    return (
      <Layout fireSearch="true">
        <div />
      </Layout>
    )
  }
  return (
    <div>
      <SEO title="Wyniki wyszukiwania" />
      <Layout>
        <div className="tags">
          <h1>
            Nie znaleziono wyników
          </h1>
        </div>
      </Layout>
    </div>
  )
}
